<template>
  <vx-card class="users-setting-page main-box md:w-1/2 top-zero-radius">
    <vs-row>
      <vs-col>
        <vs-checkbox v-model="usersSettings.requiredGender" :vs-value="true">
          اجباری بودن جنسیت
        </vs-checkbox>
      </vs-col>
    </vs-row>

    <vs-button id="saveUsersSettingBTN" class="useral-action-button"/>
  </vx-card>
</template>

<script>
export default {
  name: 'usersSetting',
  data () {
    return {
      usersSettings: {
        requiredGender: true
      },
      actions: {
        toolbar: [
          {
            id: 'saveUsersSettingBTN',
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'Settings'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)
  }
}
</script>

<style scoped>

</style>
